<template>
  <div class="sidebar">
    <v-navigation-drawer
      app
      mobile-breakpoint="1904"
      class="sidebar__nav"
      v-model="drawer"
      :permanent="$vuetify.breakpoint.xl"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-center white--text">
              メニュー
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item-group active-class="bg-active">
        <v-list nav dense>
          <!-- <v-list-group
            :value="true"
          > -->
            <!-- <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-bold text-decoration-underline">センサ共通機能</v-list-item-title>
              </v-list-item-content>
            </template> -->
            <v-list-item
              v-for="list in commonLists"
              :key="list.id"
              class="sidebar__list white--text"
              :to="list.link"
              :disabled="unableTransitionFromSideBarMenu"
            >
              <v-icon small dark class="mr-4">{{ list.icon }}</v-icon>
              <span class="text-caption">{{ list.title }}</span>
            </v-list-item>
          <!-- </v-list-group> -->
        </v-list>
      </v-list-item-group>
      <!-- <v-list-item-group active-class="bg-active">
        <v-list nav dense>
          <v-list-group
            :value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-bold text-decoration-underline">電力センサ向け機能</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="list in electricPowerLists"
              :key="list.id"
              class="sidebar__list white--text"
              :to="list.link"
              :disabled="unableTransitionFromSideBarMenu"
            >
              <v-icon small dark class="mr-4">{{ list.icon }}</v-icon>
              <span class="text-caption">{{ list.title }}</span>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-list-item-group> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    pDrawer: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      commonLists: [
        { id: 1, title: 'ダッシュボード', link: '/', icon: 'mdi-monitor-dashboard' },
        { id: 2, title: 'トレンドグラフ', link: '/trend-graph', icon: 'mdi-update' },
        // { id: 3, title: 'データ詳細', link: '/data-detail', icon: 'mdi-chart-line' },
        // { id: 4, title: 'アラーム設定', link: '/alarm', icon: 'mdi-bell' },
        // { id: 5, title: 'データダウンロード', link: '/data-download', icon: 'mdi-download' },
      ],
      electricPowerLists:[
        { id: 11, title: '電力分析', link: '/power-analysis', icon: 'mdi-calendar-today' },
        {
          id: 12,
          title: '環境負荷算出',
          link: '/environmental-impact-calculation',
          icon: 'mdi-message-text',
        },
      ],
    }
  },
  computed: {
    drawer: {
      get: function () {
        return this.pDrawer
      },
      set: function(v) {
        this.$emit('switchDrawer', v)
      }
    },
    unableTransitionFromSideBarMenu() {
      return this.$store.state.unableTransitionFromSideBarMenu
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  &__nav {
    background: linear-gradient(to bottom, #009ce6, #006ae7);
  }
}

::v-deep .v-list-item__icon {
  margin-left: 0px !important;
}
.bg-active {
  background-color: #fff;
  color: #006ae7 !important;
}
</style>
